import React, { PureComponent } from 'react'
import styled from 'styled-components'
import { Link, graphql } from 'gatsby'
import { Grid } from '@material-ui/core'

import SEO from '../components/seo'
import Layout from '../components/Layout'

const BlogContainer = styled.div`
  margin: auto;
  max-width: 1200px;
  padding: 50px;

  .header {
    text-align: center;
  }

  small {
    display: inline-block;
    margin: 5px 0;
  }
`

class Blog extends PureComponent {
  render() {
    const { data } = this.props
    const posts = data.allMarkdownRemark.edges

    return (
      <Layout>
        <SEO title="Blog" keywords={['paintball', 'field', 'blog']} />
        <BlogContainer>
          <Grid container>
            <Grid item xs={12} className="header">
              <h1>Blog</h1>
            </Grid>
            <Grid item xs={12}>
              {posts.map(({ node }) => {
                const title = node.frontmatter.title || node.fields.slug

                return (
                  <div key={node.fields.slug}>
                    <h3>
                      <Link style={{ boxShadow: `none` }} to={node.fields.slug}>
                        {title}
                      </Link>
                    </h3>
                    <div>
                      <small>{node.frontmatter.date}</small>
                      <p dangerouslySetInnerHTML={{ __html: node.excerpt }} />
                    </div>
                  </div>
                )
              })}
            </Grid>
          </Grid>
        </BlogContainer>
      </Layout>
    )
  }
}

export const blogQuery = graphql`
  query {
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
          }
        }
      }
    }
  }
`

export default Blog
